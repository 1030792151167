.notifications-list-container {
    .unread-check {
        label{
            font-size: 12px !important;
        }
    }
    .time-text {
        font-size: 12px !important;
    }
    .notification-message {
        font-size: 12px !important;
    }
}