/* Container for the chart and labels */
.customer-chart-container {
  display: flex;
  justify-content: center;
  border: 1px solid #cac6c6;
  border-radius: 5px;
  margin: 5px 0px;
  /* Align items to the left */
  .card-ticker{
    display : flex;
    align-items:center;
  }
}

.customer-chart {
  flex: 2;
  padding: 10px;
  border-right: 1px solid #cac6c6;
  // canvas{
  //   margin:  auto;
  // }
}

.customer-labels {
  display: flex;
  flex-direction: column;
  align-self: center;
  row-gap: 10px;
  padding: 10px;
}

.dashboard-boxes-container {
  border-radius: 5px;
  padding: 10px;
  flex: 2;
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 5px;
  justify-content: center;
  min-height: 300px;
  
}
.dashboard-box {
  border: 1px solid #cac6c6;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex: 1 0 30%; /* Adjust the width as needed */
  margin: 1%; /* Adjust the margin as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  
  label {
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
  }
  .live-report-data {
    color: white;
    font-weight: 700;
    font-size: 25px;
  }
  .new-icon {
    background: #fff;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .new-icon-image {
    text-align: center;
  }
}
.sc-stack {
  background: linear-gradient(
    135deg,
    rgb(171, 220, 255) 0%,
    rgb(3, 150, 255) 100%
  );
}
.sc-win{
  background: linear-gradient(-20deg, #46acf9 0%, #4e4376 100%)
}

.scr-sc{
  background: linear-gradient(rgb(0, 176, 155), rgb(150, 201, 61));
}

.usc-balance{
  background: linear-gradient(135deg, #4f0c2e 0%, #cccdcb 100%);
}
.rsc-balance{
  background: linear-gradient(135deg, #d2ba27 0%, #2351ea 100%);
}
.ggrsc-balance{
  background: linear-gradient(135deg, #d22727 0%, #23e3ea 100%);
}
.login-pie-chart-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  height: 300px;
}

.ticker-container {
  height: 200px;
  width: 300px;
  padding: 5px;
  display: flex;
  justify-content: center;
  // background-color: rgb(120,198,121);
  background: linear-gradient(#00b0b0, #44c93d);
  // align-items: center;
  // background: linear-gradient( 135deg,rgb(0 142 176), rgb(61 201 201));
  border-radius: 5px;
  color: white;
  .green-c{
    color: green;
  }
  .new-icon {
    background: #fff;
    width: 50px;
    height:50px;
    border-radius: 10px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .new-icon-image {
    text-align: center;
  }
}

.ticker-wrapper {
  display: flex;
  gap: 20px; 
  
}

.ticker-today-loginC{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  

  h5 {
    color: white;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 20px;

  }
}
.ticket-todayC{
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 3px;
 
  p {
    font-size: 20px;
    font-weight: 600;
    margin: 0px;
  }
}