.player-basic-info{
    background: #f1f1f1;
    padding:.75rem 0.375rem ;
    border-radius: .25rem;
}

.react-select{ 
    &__control {
        min-height: 43px !important;
    }
}