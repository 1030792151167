
.game-reordering-container{
    width: 100%;
    min-height: 500px;
    .pagination.row{
        margin-right: 0px !important;
    }   
    .game-reordering-subcontainer{
        width: 49%;
        float: left;
        min-height: 300px;
        max-height: 800px;

        @media screen and (max-width: 1200px) {
            width: 100%;
            margin-top: 0.5rem;
        }
        .game-reorder{
            max-height: 800pxpx;
            overflow-x: hidden;
            overflow-y: auto;
            padding-left: 10px;
            padding-right: 20px;
        }
        .game-reorder-heading{
            display: block;
            width: 100%;
            height: 40px;
            .game-heading-0{
                float: left;
                min-width: 50px;
                max-width: 25%;
                padding-left: 20px;
            }
            .game-heading-1{
                float: left;
                width: 50%;
                padding-left: 10px;
                text-align: center;
            }

            .game-heading-2{
                text-align: end;
                padding-right: 25px;
            }
        }
        
        .game-reorder-content{
            width: 100%;
            border: 1px solid rgb(240, 240, 240);
            margin-bottom: 2px;
            background-color: rgb(248, 248, 248);
            height: 40px;
            padding-top: 7px;
            padding-left: 10px;
          
            .game-id{
                float: left;
                min-width: 50px;
                max-width: 25%;
                padding-left: 10px;
            }
            .game-name{
                float: left;
                // width: 50%;
                text-align: center;
                padding-left: 10px;
            }

            .game-button{
                position: relative;
                bottom: 7px;
                float: right;
                right: 25px;
            }
        }
    }
}