.app-navbar {
  background-color: white !important;
  width: 100%;
  height: 45px;
  position: fixed;
  margin: 0;
  z-index: 999;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.menu-btn{
  position: fixed;
  left: 10px;
  z-index: 1000;
  box-shadow: none !important;
  outline: none !important;
  background-color: white !important;
  border: none;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none !important;
    transform: none !important;
    background-color: white !important;
  }

  @media screen and (min-width:768px) {
    display: none;
  }
}

.profile-btn {
  position: fixed;
  right: 65px;
  z-index: 1000;
  box-shadow: none !important;
  outline: none !important;
  background-color: white !important;
  border: none;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none !important;
    transform: none !important;
    background-color: white !important;
  }

}

.notification-btn {
  position: fixed;
  right: 40px;
  z-index: 1000;
  box-shadow: none !important;
  outline: none !important;
  background-color: white !important;
  border: none;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none !important;
    transform: none !important;
    background-color: white !important;
  }

}

.navbar-logout-btn {
  position: fixed;
  right: 10px;
  z-index: 1000;
  box-shadow: none !important;
  outline: none !important;
  background-color: white !important;
  border: none;

  &:hover,
  &:focus,
  &:active {
    box-shadow: none !important;
    transform: none !important;
    background-color: white !important;
  }

}

.notifications-content {
  position: fixed;
  right: 10px;
  z-index: 9999;
}

/* Custom SCSS for navbar notification popover positioning and styling */
.popover-container {
    position: absolute;
    z-index: 99999; // Ensure the popover is on top of other content
    pointer-events: none; // Prevent interaction with background
  }
  
  .popover-content {
    position: relative;
    display: inline-block;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    width: auto;
    max-width: 500px; // Adjust size as needed
    pointer-events: all; // Enable interaction with popover
  }
  
  .popover-arrow {
    position: absolute;
    top: -10px; // Position the arrow just above the popover content
    left: 80%;
    transform: translateX(-50%);
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #ffffff; // White arrow pointing down
    z-index: 99999;
  }
  
  .popover-header {
    padding: 8px;
    background-color: rgb(38,43,64);
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .popover-title {
    font-size: 1rem;
    font-weight: bold;
    color: #ffffff;
    margin: 0;
  }
  
  .popover-body {
    padding: 10px;
  }
  
  .close-btn {
    border: none;
    font-size: 1rem;
    cursor: pointer;
    box-shadow: none !important;
    outline: none !important;
  }